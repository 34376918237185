import React, { useState } from 'react';
import { HambergerMenu, SearchNormal1 } from 'iconsax-react';
import './../App.css';

function App() {
  // Estados para armazenar os valores inseridos pelo usuário
  const [headline, setHeadline] = useState('Lorem ipsum dolor sit amet cons adipiscing elit.');
  const [subheadline, setSubheadline] = useState('Curabitur malesuada purus magna, vitae fringilla purus aliquet sit amet. Fusce aliquet augue a dictum volutpat. Curabitur consectetur libero felis, eget condimentum nunc accumsan sit amet. ');
  const [author, setAuthor] = useState('https://');
  const [date, setDate] = useState('01/10/2024 00h00');
  const [updateTime, setUpdateTime] = useState('Atualizado há 19 minutos');

  return (
    <div className="container">
      {/* Coluna de Entrada de Texto */}
      <div className="editor">
        <h2>Editor de Notícias</h2>
        <label>Manchete</label>
        <textarea 
          value={headline} 
          onChange={(e) => setHeadline(e.target.value)} 
          rows="4"
        />

        <label>Submanchete</label>
        <textarea 
          value={subheadline} 
          onChange={(e) => setSubheadline(e.target.value)} 
          rows="6"
        />

        <label>Link imagem</label>
        <input 
          type="text" 
          value={author} 
          onChange={(e) => setAuthor(e.target.value)}
        />

      </div>

      {/* Coluna de Pré-visualização */}
      <div className="preview">
        <header className="preview-header">
            <SearchNormal1 style={{float: 'right'}} />
            <HambergerMenu />
            <h2 style={{textAlign: 'center', marginTop: -25}}>NOTÍCIA</h2>
        </header>

        <div className="preview-content">
          <h2>{headline}</h2>
          <div id="subt">{subheadline}</div>
        </div>

        <img src="man-5966463_640.jpg" style={{width: '90%', marginLeft: '5%'}} />
        <br/> <br/>
        <center>
        <button style={{border: 'none', padding: 20, alignSelf: 'center'}}>Download</button>
        </center>
        <br/> <br/>
      </div>
    </div>
  );
}

export default App;
